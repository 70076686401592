import script from "./App.vue?vue&type=script&setup=true&lang=ts"
export * from "./App.vue?vue&type=script&setup=true&lang=ts"

import "./App.vue?vue&type=style&index=0&id=5ec80153&lang=scss"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import qInstall from "../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QPageContainer,QBtn,QLinearProgress});
